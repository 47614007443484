import React, { FunctionComponent, useContext, useEffect, useState } from 'react';
import InputLabel from '@material-ui/core/InputLabel';
import { useTranslation } from 'react-i18next';
import { Box, CircularProgress, createStyles, Grid, makeStyles, Paper, TextField, Theme } from '@material-ui/core';
import StepperButtons from './StepperButtons';
import { getApiService } from '../utils/apiRequest';
import { EMAIL_TEMPLATES } from '../utils/emailTemplates';
import { Alert } from '@material-ui/lab';
import { IEventPlaceholders } from '../../../sharedTypes';
import MicroFrontendContext from '../components/MicroFrontendContext';

interface IStep2Props {
  emailTemplate: string;
  eventIds: string[];
  placeholders: Record<string, string | number | boolean>;
  eventsPlaceholders: IEventPlaceholders[];
  handleNext: (
    placeholders: Record<string, string | number | boolean>,
    eventsPlaceholders: IEventPlaceholders[]
  ) => void;
  handleBack: () => void;
}

const Step2: FunctionComponent<IStep2Props> = (props) => {
  const { t } = useTranslation();

  const [placeholders, setPlaceholders] = useState<Record<string, string | number | boolean>>(props.placeholders);
  const [eventsPlaceholders, setEventsPlaceholders] = useState<IEventPlaceholders[]>(props.eventsPlaceholders);
  const [eventLoading, setEventLoading] = useState(true);
  const [eventsNotFound, setEventsNotFound] = useState<string[]>([]);

  const { getIdToken } = useContext(MicroFrontendContext);

  const styleInput = {
    textAlign: 'right',
    alignSelf: 'center',
    paddingRight: '20px',
    '& label': {
      fontWeight: '800',
    },
  };

  useEffect(() => {
    setEventsNotFound([]);
    const api = getApiService(getIdToken);
    api
      .getEventData(props.eventIds.join(','))
      .then((result) => {
        if (result) {
          setEventsPlaceholders(result);
          const eventsNotFound = props.eventIds.filter((item) => !result.some((obj) => obj.SapEventId === item));
          setEventsNotFound(eventsNotFound);
        } else {
          setEventsNotFound(props.eventIds);
        }
      })
      .catch((error) => {
        setEventsNotFound(props.eventIds);
      })
      .finally(() => {
        setEventLoading(false);
      });
  }, [props.eventIds, getIdToken]);

  const handlePlaceholderChange = (key: string, value: string) => {
    setPlaceholders((currentPlaceholders) => ({
      ...currentPlaceholders,
      [key]: value,
    }));
  };

  const useStyles = makeStyles((theme: Theme) =>
    createStyles({
      button: {
        display: 'block',
        marginTop: theme.spacing(2),
      },
      formControl: {
        width: '100%',
      },
      formRow: {
        padding: theme.spacing(1),
      },
    })
  );
  const classes = useStyles();

  if (eventLoading) {
    return <CircularProgress />;
  }

  if (eventsNotFound.length > 0) {
    return (
      <>
        <Box component={Paper} my={2} p={3}>
          <Alert severity="error">
            {t('emailNotification.wizard.step2.eventNotFound', { eventId: eventsNotFound })}
          </Alert>
        </Box>

        <StepperButtons
          nextButtonDisabled={false}
          handleBack={props.handleBack}
          backLabel={t('emailNotification.wizard.buttons.back')}
          nextLabel={t('emailNotification.wizard.buttons.next')}
        />
      </>
    );
  }

  return (
    <>
      <Box component={Paper} my={2} p={3}>
        {EMAIL_TEMPLATES[props.emailTemplate]?.map((placeholder) => {
          return (
            <Grid container item className={classes.formRow} key={placeholder.id} xs={12}>
              <Grid item xs={2} component={Box} sx={styleInput}>
                <InputLabel required={placeholder.required} htmlFor={placeholder.id}>
                  {t(`placeholders.${placeholder.id}`)}
                </InputLabel>
              </Grid>
              <Grid item xs={10}>
                <TextField
                  id={placeholder.id}
                  required={placeholder.required}
                  value={placeholders[placeholder.id] ?? ''}
                  onChange={(event: React.ChangeEvent<{ value: unknown }>) =>
                    handlePlaceholderChange(placeholder.id, event.target.value as string)
                  }
                  className={classes.formControl}
                />
              </Grid>
            </Grid>
          );
        })}
      </Box>

      <StepperButtons
        nextButtonDisabled={EMAIL_TEMPLATES[props.emailTemplate]?.some(
          (placeholder) => placeholder.required && !placeholders[placeholder.id]
        )}
        handleBack={props.handleBack}
        handleNext={() => props.handleNext(placeholders, eventsPlaceholders)}
        nextLabel={t('emailNotification.wizard.buttons.next')}
        backLabel={t('emailNotification.wizard.buttons.back')}
      />
    </>
  );
};

export default Step2;
