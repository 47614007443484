import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Button,
  Checkbox,
  Chip,
  CircularProgress,
  createStyles,
  FormControl,
  FormControlLabel,
  FormGroup,
  Grid,
  InputLabel,
  makeStyles,
  MenuItem,
  Paper,
  Select,
  Snackbar,
  Table,
  TableBody,
  TableCell,
  TableRow,
  TextField,
  Theme,
  Typography,
} from '@material-ui/core';
import StepperButtons from './StepperButtons';
import { getApiService } from '../utils/apiRequest';
import { FunctionComponent, useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Alert } from '@material-ui/lab';
import { IEventPlaceholders, IRecipient } from '../../../sharedTypes';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import PersonIcon from '@material-ui/icons/Person';
import MicroFrontendContext from '../components/MicroFrontendContext';

interface IStep3Props {
  emailTemplate: string;
  eventIds: string[];
  placeholders: Record<string, string | number | boolean>;
  eventsPlaceholders: IEventPlaceholders[];
  handleNext: () => void;
  handleBack: () => void;
}

interface IRecipientsByEvent {
  [id: string]: IRecipient[];
}

interface IExludedRecipients {
  [id: string]: string[];
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    table: {
      marginBottom: theme.spacing(5),
    },
  })
);

const Step3: FunctionComponent<IStep3Props> = (props) => {
  const { getIdToken } = useContext(MicroFrontendContext);

  const { t } = useTranslation();

  const [recipients, setRecipients] = useState<IRecipientsByEvent>({});
  const [exludedRecipients, setExludedRecipients] = useState<IExludedRecipients>({});
  const [recipientsLoading, setRecipientsLoading] = useState(true);
  const [recipientsError, setRecipientsError] = useState(false);
  const [sendingTestEmail, setSendingTestEmail] = useState(false);
  const [sendTestMailError, setSendTestMailError] = useState(false);
  const [sendTestMailSuccess, setSendTestMailSuccess] = useState(false);
  const [sendingTestEventId, setSendingTestEventId] = useState(props.eventsPlaceholders[0].SapEventId);
  const [testRecipient, setTestRecipient] = useState('');

  useEffect(() => {
    if (props.eventIds.length > 0) {
      const api = getApiService(getIdToken);
      props.eventIds.map(async (id) => {
        return api
          .getRecipients(id)
          .then((result) => {
            setRecipients((current) => ({
              ...current,
              [id]: result,
            }));
            setExludedRecipients((current) => ({
              ...current,
              [id]: [],
            }));
            setRecipientsError(false);
          })
          .catch((error) => {
            setRecipientsError(true);
          })
          .finally(() => {
            setRecipientsLoading(false);
          });
      });
    }
  }, [getIdToken, props.eventIds]);

  const sendEmails = async () => {
    const api = getApiService(getIdToken);
    await api.sendEventNotification(
      props.eventIds,
      props.emailTemplate,
      props.placeholders,
      undefined,
      exludedRecipients
    );
    props.handleNext();
  };

  const sendTestEmail = async () => {
    const api = getApiService(getIdToken);
    try {
      setSendTestMailSuccess(false);
      setSendingTestEmail(true);
      await api.sendEventNotification(
        [sendingTestEventId],
        props.emailTemplate,
        props.placeholders,
        testRecipient,
        undefined,
        props.eventsPlaceholders.find((event) => event.SapEventId === sendingTestEventId)
      );
      setSendTestMailError(false);
      setSendTestMailSuccess(true);
      setTestRecipient('');
    } catch (error) {
      setSendTestMailError(true);
    } finally {
      setSendingTestEmail(false);
    }
  };

  const classes = useStyles();

  const handleTestEmailSuccessClose = (event?: React.SyntheticEvent, reason?: string) => {
    setSendTestMailSuccess(false);
  };

  const handleTestEmailErrorClose = (event?: React.SyntheticEvent, reason?: string) => {
    setSendTestMailError(false);
  };

  const handleCheck = (checkEvent: React.ChangeEvent<HTMLInputElement>, event: string, userId: string) => {
    const { checked } = checkEvent.target;
    setExludedRecipients((current) => ({
      ...current,
      [event]: checked
        ? exludedRecipients[event].filter((obj) => obj !== userId)
        : [...exludedRecipients[event], userId],
    }));
  };

  return (
    <>
      <Box component={Paper} my={2} p={3}>
        <Grid container spacing={10}>
          <Grid item xs={6}>
            <Typography gutterBottom variant="h5" component="h3">
              {t('emailNotification.wizard.step3.summary')}
            </Typography>
            <Table className={classes.table}>
              <TableBody>
                <TableRow key="template-name">
                  <TableCell component="th" scope="row">
                    {t('emailNotification.wizard.step3.emailTemplate')}
                  </TableCell>
                  <TableCell>{t(`emailNotification.wizard.step1.templates.${props.emailTemplate}`)}</TableCell>
                </TableRow>
              </TableBody>
            </Table>
            <Typography gutterBottom variant="h5" component="h3">
              {t('emailNotification.wizard.step3.placeholders')}
            </Typography>
            <Table className={classes.table}>
              <TableBody>
                {Object.entries(props.placeholders).map(([key, value]) => {
                  return (
                    <TableRow key={key}>
                      <TableCell component="th" scope="row" width={300}>
                        {t(`placeholders.${key}`)}
                      </TableCell>
                      <TableCell>{typeof value === 'boolean' ? value.toString() : value}</TableCell>
                    </TableRow>
                  );
                })}
              </TableBody>
            </Table>
            <Box>
              <Grid container spacing={10}>
                <Grid item xs={6}>
                  <TextField
                    id="testRecipientInput"
                    value={testRecipient}
                    label="Test E-Mail senden an"
                    variant="standard"
                    disabled={sendingTestEmail}
                    fullWidth={true}
                    onChange={(event: React.ChangeEvent<{ value: unknown }>) =>
                      setTestRecipient(event.target.value as string)
                    }
                  />
                </Grid>
                <Grid item xs={6}>
                  <FormControl fullWidth={true}>
                    <InputLabel id="test-event-select-label">Test Veranstaltung</InputLabel>
                    <Select
                      fullWidth={true}
                      autoWidth={true}
                      labelId="test-event-select-label"
                      id="test-event-select"
                      value={sendingTestEventId}
                      onChange={(event) => setSendingTestEventId(event.target.value as string)}
                    >
                      {props.eventsPlaceholders.map((event) => (
                        <MenuItem value={event.SapEventId}>{event.EventTitle}</MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </Grid>
              </Grid>
              <Button
                style={{ marginTop: 10 }}
                disabled={testRecipient.length === 0 || sendingTestEmail}
                onClick={sendTestEmail}
                variant="contained"
                color="primary"
              >
                {t('emailNotification.wizard.step3.sendTestEmail')}
              </Button>
            </Box>
          </Grid>
          <Grid item xs={6}>
            <Typography gutterBottom variant="h5" component="h2">
              {t('emailNotification.wizard.step3.event_other')}
            </Typography>
            <br />
            {Object.keys(recipients).length > 0 &&
              props.eventsPlaceholders.map(
                (event) =>
                  recipients.hasOwnProperty(event.SapEventId) && (
                    <Accordion key={event.SapEventId} elevation={2}>
                      <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="panel1a-content"
                        id="panel1a-header"
                      >
                        <Box display={'flex'} alignItems={'center'} width={'100%'}>
                          <div style={{ flexGrow: 1 }}>
                            [{event.SapEventId} / {event.BookingNumber}] <strong>{event.EventTitle}</strong> (
                            {event.Status})
                          </div>
                          {recipientsLoading ? (
                            <CircularProgress size={20} />
                          ) : (
                            <Chip
                              icon={<PersonIcon />}
                              label={recipients[event.SapEventId].length}
                              color="primary"
                              variant="outlined"
                              style={{ borderRadius: '100px', borderWidth: 0 }}
                            />
                          )}
                        </Box>
                      </AccordionSummary>
                      <AccordionDetails>
                        <Box>
                          {recipientsError && (
                            <Alert severity="error">{t('emailNotification.wizard.step3.recipientsError')}</Alert>
                          )}
                          {recipientsLoading ? (
                            <CircularProgress />
                          ) : (
                            <FormGroup>
                              {recipients[event.SapEventId]?.map((recipient) => (
                                <FormControlLabel
                                  key={recipient.hmguserid}
                                  control={
                                    <Checkbox
                                      defaultChecked
                                      onChange={(eventCheck) =>
                                        handleCheck(eventCheck, event.SapEventId, recipient.hmguserid)
                                      }
                                    />
                                  }
                                  label={recipient.sapUserId}
                                />
                              ))}
                            </FormGroup>
                          )}
                        </Box>
                      </AccordionDetails>
                    </Accordion>
                  )
              )}
          </Grid>
        </Grid>
      </Box>

      <StepperButtons
        nextButtonDisabled={false}
        handleBack={props.handleBack}
        handleNext={sendEmails}
        nextLabel={t('emailNotification.wizard.buttons.sendEmails')}
        backLabel={t('emailNotification.wizard.buttons.back')}
      />
      <Snackbar open={sendTestMailSuccess} autoHideDuration={5000} onClose={handleTestEmailSuccessClose}>
        <Alert severity="success" onClose={handleTestEmailSuccessClose}>
          {t('emailNotification.wizard.step3.testEmailSent')}
        </Alert>
      </Snackbar>

      <Snackbar open={sendTestMailError} autoHideDuration={5000} onClose={handleTestEmailErrorClose}>
        <Alert severity="error" onClose={handleTestEmailErrorClose}>
          {t('emailNotification.wizard.step3.errorSendingTestEmail')}
        </Alert>
      </Snackbar>
    </>
  );
};

export default Step3;
