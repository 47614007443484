import config from '../config';

import {
  IBatch,
  IBatchResponse,
  IBatchGroup,
  IEventPlaceholders,
  IRecipientsResponse,
  IBatchGroupResponse,
  INotification,
  INotificationResponse,
  IRecipient,
} from '../../../sharedTypes';

export const getApiService = (getIdToken: () => Promise<string>) => {
  const apiRequest = async <T>(path: string, method: 'GET' | 'POST', body?: BodyInit) => {
    const result = await fetch(`${config.API_URL}/${config.API_PATH_PREFIX}/${path}`, {
      method,
      body,
      headers: {
        'Content-Type': 'application/json',
        authorization: `Bearer ${(await getIdToken()) ?? ''}`,
      },
    });

    if (result.status >= 400) {
      const data = await result.json();
      throw new Error(data.message);
    }

    return (await result.json()) as T;
  };

  const getEventData = async (eventId: string) => {
    return await apiRequest<IEventPlaceholders[]>(`events/${eventId}/placeholders`, 'GET');
  };

  const getRecipients = async (eventId: string): Promise<IRecipient[]> => {
    const result = await apiRequest<IRecipientsResponse>(`events/${eventId}/recipients`, 'GET');

    return result.items;
  };

  const sendEventNotification = async (
    eventIds: string[],
    emailTemplate: string,
    placeholders: Record<string, string | number | boolean>,
    testRecipient?: string,
    exludedRecipients?: Record<string, string[]>,
    eventsPlaceholder?: IEventPlaceholders
  ) => {
    return await apiRequest(
      `email-notifications`,
      'POST',
      JSON.stringify({
        templateId: emailTemplate,
        placeholders,
        eventsPlaceholder,
        testRecipient,
        eventIds,
        exludedRecipients,
      })
    );
  };

  const getBatches = async (): Promise<IBatch[]> => {
    const result = await apiRequest<IBatchResponse>('batches', 'GET');

    return result.items;
  };

  const getBatchGroups = async (batchId: string): Promise<IBatchGroup[]> => {
    const result = await apiRequest<IBatchGroupResponse>(`batches/${batchId}/groups`, 'GET');

    return result.items;
  };

  const getNotifications = async (batchId: string, batchGroupId: string): Promise<INotification[]> => {
    const result = await apiRequest<INotificationResponse>(
      `batches/${batchId}/groups/${batchGroupId}/notifications`,
      'GET'
    );

    return result.items;
  };

  return {
    getBatches,
    getBatchGroups,
    getEventData,
    getNotifications,
    getRecipients,
    sendEventNotification,
  };
};
