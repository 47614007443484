import {
  Collapse,
  createStyles,
  IconButton,
  LinearProgress,
  makeStyles,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Theme,
} from '@material-ui/core';
import { Fragment, FunctionComponent, useContext, useEffect, useState } from 'react';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp';
import GroupTableRow from './GroupTableRow';
import { useTranslation } from 'react-i18next';
import { getApiService } from '../../utils/apiRequest';
import { Alert } from '@material-ui/lab';
import { format } from 'date-fns';
import { IBatch, IBatchGroup } from '../../../../sharedTypes';
import MicroFrontendContext from '../MicroFrontendContext';

interface IBatchTableRowProps {
  batch: IBatch;
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      width: '100%',
    },
    button: {
      marginRight: theme.spacing(1),
    },
    content: {
      marginLeft: theme.spacing(5),
      marginRight: theme.spacing(5),
      marginTop: theme.spacing(1),
      marginBottom: theme.spacing(1),
    },
    fab: {
      position: 'absolute',
      bottom: theme.spacing(2),
      right: theme.spacing(2),
    },
    headerCell: {
      borderBottom: 0,
    },
    collapseButton: {
      width: 30,
    },
  })
);

const BatchTableRow: FunctionComponent<IBatchTableRowProps> = (props) => {
  const [batchGroupsLoaded, setBatchGroupsLoaded] = useState(false);
  const [batchGroups, setBatchGroups] = useState<IBatchGroup[]>([]);
  const [batchGroupsLoading, setBatchGroupsLoading] = useState(false);
  const [batchGroupsError, setBatchGroupsError] = useState(false);
  const [open, setOpen] = useState(false);
  const { t } = useTranslation();
  const { getIdToken } = useContext(MicroFrontendContext);

  const classes = useStyles();

  useEffect(() => {
    if (open && !batchGroupsLoaded) {
      setBatchGroupsLoading(true);
      const api = getApiService(getIdToken);
      api
        .getBatchGroups(props.batch.id)
        .then((result) => {
          setBatchGroups(result);
          setBatchGroupsError(false);
          setBatchGroupsLoaded(true);
        })
        .catch((error) => {
          setBatchGroupsError(true);
        })
        .finally(() => {
          setBatchGroupsLoading(false);
        });
    }
  }, [getIdToken, props.batch.id, open, batchGroupsLoaded]);

  const handleOpenBatchGroups = () => {
    setOpen(!open);
  };

  const getContent = () => {
    if (batchGroupsLoading) {
      return <LinearProgress />;
    }

    if (batchGroupsError) {
      return <Alert severity="error">{t('emailNotification.list.errorLoadingBatchGroups')}</Alert>;
    } else {
      return (
        <Collapse in={open} timeout="auto" unmountOnExit>
          <Table size="small" aria-label="purchases">
            <TableHead>
              <TableRow>
                <TableCell />
                <TableCell>{t('emailNotification.list.eventName')}</TableCell>
                <TableCell>{t('emailNotification.list.sentEmails')}</TableCell>
                <TableCell align="right">{t('emailNotification.list.status')}</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {batchGroups?.map((batchGroup, index) => (
                <GroupTableRow open={index === 0} key={`batch-group-${index}`} batchGroup={batchGroup} />
              ))}
            </TableBody>
          </Table>
        </Collapse>
      );
    }
  };

  return (
    <Fragment>
      <TableRow className={classes.root}>
        <TableCell className={`${classes.headerCell} ${classes.collapseButton}`}>
          <IconButton aria-label="expand row" size="small" onClick={handleOpenBatchGroups}>
            {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
          </IconButton>
        </TableCell>
        <TableCell className={classes.headerCell} component="th" scope="row">
          {format(new Date(props.batch.createdAt), t('dateFormat'))}
        </TableCell>
        <TableCell className={classes.headerCell} align="right">
          {t(`emailNotification.wizard.step1.templates.${props.batch.templateId}`)}
        </TableCell>
      </TableRow>
      <TableRow>
        <TableCell style={{ paddingLeft: 60 }} colSpan={4}>
          {getContent()}
        </TableCell>
      </TableRow>
    </Fragment>
  );
};

export default BatchTableRow;
