import { createRoot } from 'react-dom/client';

import './i18n';

import './theme/FontFace.css';
import LightHouseThemeProvider from './theme/LightHouseThemeProvider';
import mergedConfig, { MICROFRONTEND_ID } from './config';
import { BrowserRouter, Navigate, Route, Routes } from 'react-router-dom';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';
import { IMicrofrontendProps, Microfrontend } from '@hmg-aka-lu/cockpit-microfrontend';
import { FunctionComponent } from 'react';
import EmailNotifications from './pages/EmailNotifications';
import CreateNotificationWizard from './pages/CreateNotificationWizard';
import MicroFrontendContext from './components/MicroFrontendContext';
declare global {
  interface Window {
    [MICROFRONTEND_ID]: Microfrontend;
  }
}

const LGBMicrofrontend: FunctionComponent<IMicrofrontendProps> = ({ getIdToken, permissions }) => {
  const queryClient = new QueryClient();

  return (
    <LightHouseThemeProvider>
      <QueryClientProvider client={queryClient}>
        <ReactQueryDevtools initialIsOpen={false} />
        <MicroFrontendContext.Provider value={{ getIdToken, permissions: permissions }}>
          <BrowserRouter>
            <Routes>
              <Route path={`/${MICROFRONTEND_ID}`} element={<EmailNotifications />} />
              <Route path={`/${MICROFRONTEND_ID}/new`} element={<CreateNotificationWizard />} />
              <Route path="*" element={<Navigate replace to={`/${MICROFRONTEND_ID}`} />} />
            </Routes>
          </BrowserRouter>
        </MicroFrontendContext.Provider>
      </QueryClientProvider>
    </LightHouseThemeProvider>
  );
};

const microFrontendInstance: Microfrontend = (containerId = 'root', props) => {
  const rootElement = document.getElementById(containerId);
  if (!rootElement) {
    throw new Error('DOM root node not found');
  }

  return {
    render: () => {
      createRoot(rootElement).render(
        <LGBMicrofrontend getIdToken={props.getIdToken} permissions={props.permissions} />
      );
    },
  };
};

window.emailNotifications = microFrontendInstance;

if (mergedConfig.STANDALONE) {
  const microFrontend = microFrontendInstance('root', {
    permissions: ['Superuser'],
    getIdToken: async () => '',
  });
  microFrontend.render();
}
